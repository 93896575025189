import React from 'react'
import '../styles/App.css'

const App: React.FC = () => {
  return (
    <div className="App">
      <div className="bangos-text">bangos</div>
    </div>
  )
}

export default App
